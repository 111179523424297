<template>
  <nuxt-link to="/signin" v-if="!user" tag="div" class="profile">
    <div class="wrapper">
      <div class="poster">
        <!-- <img :data-src="'/static_files/icons/gamer.svg'" src="/static_files/no-poster.png" class="lazy" alt=""> -->
        <nuxt-img
          v-if="isDesktop"
          src="/static_files/icons/gamer.svg"
          alt="Avatar"
          preload
          fit="cover"
          width="40"
          height="40"
          format="svg"
        />
        <nuxt-img
          v-else
          src="/static_files/icons/gamer.svg"
          alt="Avatar"
          preload
          fit="cover"
          width="24"
          height="24"
          format="svg"
        />
      </div>
      <strong v-if="isDesktop">Войти</strong>
    </div>
  </nuxt-link>
  <div v-else class="profile">
    <div @click="showMenu = !showMenu" class="wrapper">
      <div class="poster">
        <img :src="user.photo" alt="">
      </div>
      <strong v-if="isDesktop" class="name">{{ user.name }}</strong>
    </div>
    <div v-if="showMenu" class="short-menu">
      <button @click="logout">
        <img src="/static_files/icons/exit-dark.svg" alt="">
        Выйти
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
 computed: mapState(['user', 'isDesktop']),
 data() {
  return {
    showMenu: false
  }
 },
 methods: {
  async logout() {
      await this.$axios.get('/api/auth/logout')
      this.$store.commit('setUser', null)
      location.reload()
    },
 }
}
</script>